import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import type { IconName } from '@st/ui/components/StIcon/types'
import { sortBy } from '@st/utils'
import { useUserStore } from '@st/user/stores/useUserStore'
import type { PrizeTopList, Tournament, UserPlace } from '../types'

interface UseTournamentReturn {
  isProcessed: ComputedRef<boolean>
  isCalculated: ComputedRef<boolean>
  formattedAmount: ComputedRef<string>
  currencyIcon: ComputedRef<IconName | null>
  prizesTopList: ComputedRef<PrizeTopList[]>
  isRiseUp: ComputedRef<boolean>
  currentUserPlace: Ref<UserPlace | null>
  finalTopListRow: ComputedRef<PrizeTopList>
  isUserInWinners: ComputedRef<boolean>
  isBonusClaimedByUser: ComputedRef<boolean>
}

export function useTournament(
  tournament: Ref<Tournament>,
): UseTournamentReturn {
  const isProcessed = computed(() => tournament.value.status === 'processed')
  const isCalculated = computed(() => tournament.value.status === 'calculating')

  const { format } = useCurrencyFormatter()
  const formattedAmount = computed(() =>
    format(tournament.value.totalPrizePoolAmount),
  )
  // TODO rise up logic
  const isRiseUp = computed(
    () => tournament.value.prizePoolType === 'dynamic' && !isProcessed.value,
  )

  const { currencies } = storeToRefs(useCurrenciesStore())
  const currencyIcon = computed(() => {
    const currencyById = currencies.value[tournament.value.currencyId]
    return currencyById ? currencyById.icon : null
  })

  const sortedTopList = computed(() =>
    sortBy(tournament.value.prizeTopList, (user) => [user.prizePlace]),
  )
  const prizesTopList = computed(() => sortedTopList.value.slice(0, 3))

  const currentUserPlace = ref<UserPlace | null>(null)
  const { user } = storeToRefs(useUserStore())

  const finalTopListRow = computed(() => {
    if (
      !currentUserPlace.value?.prizePlace ||
      currentUserPlace.value.prizePlace <= 3 ||
      currentUserPlace.value.prizePlace > tournament.value.prizePlaceNumber
    )
      return sortedTopList.value[3]

    return {
      prizePlace: currentUserPlace.value.prizePlace,
      userNickname: user.value?.nickname || null,
      userDropAmount: currentUserPlace.value.amountOfDrop ?? '0',
      prizeAmount: currentUserPlace.value.amountOfPrize ?? '0',
    }
  })

  const isUserInWinners = computed(() => {
    if (!currentUserPlace.value?.prizePlace) return false

    return (
      currentUserPlace.value.prizePlace <= tournament.value.prizePlaceNumber
    )
  })

  const isBonusClaimedByUser = computed(
    () => !!currentUserPlace.value?.isBonusClaimed,
  )

  const stFetch = useRawStFetch()
  watchEffect(async () => {
    if (user.value && !currentUserPlace.value) {
      try {
        currentUserPlace.value = await stFetch('/rat-race/find-place', {
          method: 'post',
          body: {
            ratRaceId: tournament.value.id,
          },
        })
      } catch {
        console.error('User place not found')
      }
    }
  })

  return {
    isProcessed,
    isCalculated,
    formattedAmount,
    currencyIcon,
    prizesTopList,
    isRiseUp,
    currentUserPlace,
    finalTopListRow,
    isUserInWinners,
    isBonusClaimedByUser,
  }
}
